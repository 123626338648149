import fileText from './app-ads.txt';

// React component
function AppAdsFile() {
  return (
    <>
      {fileText}
    </>
  );
}

export default AppAdsFile;